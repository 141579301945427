






import { Component, Prop, Vue } from 'vue-property-decorator'
import User from '@/shared/modules/user/user.model'
import getInitials from '@/shared/helpers/get-initials.helper'

@Component({
  methods: {
    getInitials
  }
})
export default class UserAvatar extends Vue {
  @Prop() user!: User | null
}
