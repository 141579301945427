








import { Component, Vue } from 'vue-property-decorator'
import AdminLayout from '@/admin/layouts/AdminLayout.vue'
import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import AuthLayout from '@/admin/layouts/AuthLayout.vue'
import NotFound from '@/shared/components/NotFound.vue'

@Component({
  components: { AuthLayout, AdminLayout, NotFound }
})
export default class Admin extends Vue {

  get hasAccess(): boolean {
    let hasAccess = true
    if (this.isLogged && ! this.user?.isSuperAdmin) {
      hasAccess = false
    }

    return hasAccess
  }

  get isLogged(): boolean {
    return this.$store.getters[AuthGetters.isLogged]
  }

  get user(): User | null {
    return this.$store.getters[AuthGetters.getUser]
  }
}
