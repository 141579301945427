























import { Component, Prop, Vue } from 'vue-property-decorator'
import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import IUserItem from '@/shared/interfaces/admin/components/layout/user-item.interface'
import UserAvatar from '@/shared/components/layout/UserAvatar.vue'

@Component({
  components: { UserAvatar }
})
export default class UserMenu extends Vue {
  @Prop() items!: IUserItem[]

  get user(): User | null {
    return this.$store.getters[AuthGetters.getUser]
  }
}
