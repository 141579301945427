


















import { Component, Prop, Vue } from 'vue-property-decorator'
import { GlobalGetters } from '@/shared/store/global/global.getters'
import MultiLanguage from '@/shared/components/MultiLanguage.vue'
import UserMenu from '@/shared/components/layout/UserMenu.vue'
import { GlobalActions } from '@/shared/store/global/global.actions'
import IUserItem from '@/shared/interfaces/admin/components/layout/user-item.interface'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import { RoleLevel } from '@/shared/modules/role/role.model'
import EmployeeAddTaskDialog from '@/company/components/tasks/add/EmployeeAddTaskDialog.vue'
import ManagerAddTaskDialog from '@/company/components/tasks/add/ManagerAddTaskDialog.vue'

@Component({
  components: {
    UserMenu,
    MultiLanguage,
  }
})
export default class Header extends Vue {
  @Prop() userMenuItems!: IUserItem[]
  @Prop() logo!: string
  roleLevel = RoleLevel
  toggleNavigation = () => this.$store.dispatch(GlobalActions.toggleNavigation)

  openTaskAndTimeCreateDialog(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: this.user.role.level === RoleLevel.employee ? EmployeeAddTaskDialog : ManagerAddTaskDialog,
      persistent: true,
      maxWidth: 980,
      meta: {}
    })
  }

  get loading(): boolean {
    return this.$store.getters[GlobalGetters.getLoading]
  }

  get company(): Company | null {
    return this.$store.getters[CompanyGetters.getCompany]
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }
}
