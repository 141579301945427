import { RoleLevel } from "@/shared/modules/role/role.model";

export default class NavigationItem {
  title!: string
  icon!: string
  route!: string
  open: boolean = false
  subItems: NavigationItem[] = []
  permissions!: string[]
  allowedForRole: RoleLevel[] = []
  atLeastOne!: boolean
  childOpen!: string
  isVisible: boolean = true

  setTitle(title: string): NavigationItem {
    this.title = title
    return this
  }

  setIcon(icon: string): NavigationItem {
    this.icon = icon
    return this
  }

  setRoute(route: string): NavigationItem {
    this.route = route
    return this
  }

  setOpen(open: boolean): NavigationItem {
    this.open = open
    return this
  }

  setSubItems(subItems: NavigationItem[]): NavigationItem {
    this.subItems = subItems
    return this
  }

  setPermissions(permissions: string[]): NavigationItem {
    this.permissions = permissions
    return this
  }
  
  setAllowedForRole(allowedForRole: RoleLevel[]): NavigationItem {
    this.allowedForRole = allowedForRole
    return this
  }

  setPermissionsAtLeastOne(atLeastOne: boolean): NavigationItem {
    this.atLeastOne = atLeastOne
    return this
  }

  setIsVisible(isVisible: boolean): NavigationItem {
    this.isVisible = isVisible
    return this
  }

  setChildOpen(child: string): NavigationItem {
    this.childOpen = child
    return this
  }
}
