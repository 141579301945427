














import { Component, Vue } from 'vue-property-decorator'
import { GlobalGetters } from '@/shared/store/global/global.getters'
import { NavigationState } from '@/shared/store/global/global.state'
import User from '@/shared/modules/user/user.model'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import Header from '@/shared/components/layout/Header.vue'
import Navigation from '@/shared/components/layout/Navigation.vue'
import NavigationItem from '@/shared/classes/layouts/navigation-item'
import __ from '@/shared/helpers/__'
import { AdminRoutes } from '@/shared/router/admin'
import IUserItem from '@/shared/interfaces/admin/components/layout/user-item.interface'

@Component({
  components: {
    Header,
    Navigation
  }
})
export default class AdminLayout extends Vue {
  navigationItems: NavigationItem[] = [
    // new NavigationItem()
    //   .setTitle(__('admin.layouts.default.navigation.dashboard'))
    //   .setIcon('mdi-view-dashboard')
    //   .setRoute(AdminRoutes.dashboard),
    new NavigationItem()
      .setTitle(__('admin.layouts.default.navigation.companies'))
      .setIcon('mdi-domain')
      .setRoute(AdminRoutes.companiesIndex),
    new NavigationItem()
      .setTitle(__('admin.layouts.default.navigation.users'))
      .setIcon('mdi-account-supervisor')
      .setRoute(AdminRoutes.usersIndex),
    new NavigationItem()
      .setTitle(__('admin.layouts.default.navigation.courses'))
      .setIcon('mdi-school')
      .setSubItems([
        new NavigationItem()
          .setTitle(__('company.layouts.default.navigation.course-list'))
          .setRoute(AdminRoutes.coursesIndex),
        new NavigationItem()
          .setTitle(__('company.layouts.default.navigation.courses-categories'))
          .setRoute(AdminRoutes.coursesCategories),
      ]),
    new NavigationItem()
      .setTitle(__('admin.layouts.default.navigation.hse'))
      .setIcon('mdi-hard-hat')
      .setSubItems([
        new NavigationItem()
          .setTitle(__('company.layouts.default.navigation.hse-routine-categories'))
          .setRoute(AdminRoutes.hseRoutineCategories),
        new NavigationItem()
          .setTitle(__('company.layouts.default.navigation.hse-routine-templates'))
          .setRoute(AdminRoutes.hseRoutineTemplatesIndex),
        new NavigationItem()
          .setTitle(__('company.layouts.default.navigation.hse-risk-categories'))
          .setRoute(AdminRoutes.hseRiskCategories),
        new NavigationItem()
          .setTitle(__('company.layouts.default.navigation.hse-risk-templates'))
          .setRoute(AdminRoutes.hseRiskTemplatesIndex),
      ]),
    new NavigationItem()
      .setTitle(__('company.layouts.default.navigation.ks-instructions'))
      .setIcon('mdi-list-status')
      .setSubItems([
        new NavigationItem()
          .setTitle(__('company.layouts.default.navigation.ks-instruction-categories'))
          .setRoute(AdminRoutes.instructionCategories),
        new NavigationItem()
          .setTitle(__('company.layouts.default.navigation.ks-instruction-templates'))
          .setRoute(AdminRoutes.instructionTemplatesIndex),
      ]),
    new NavigationItem()
      .setTitle(__('admin.layouts.default.navigation.imports'))
      .setIcon('mdi-cog')
      .setRoute(AdminRoutes.imports)
  ]

  userMenuItems: IUserItem[] | null = null

  mounted() {
    this.userMenuItems = [
      {
        icon: 'mdi-account',
        title: __('admin.layouts.default.user-menu.profile'),
        action: () => this.$router.push({ name: AdminRoutes.profile })
      },
      {
        icon: 'mdi-logout',
        title: __('admin.layouts.default.user-menu.logout'),
        action: () => this.$router.push({ name: AdminRoutes.logout }).catch(() => {})
      }
    ]
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }

  get contentClass(): string {
    const navigationState = this.$store.getters[GlobalGetters.getNavigation]

    if (navigationState === NavigationState.minimized) {
      return 'content-minimized'
    }

    return 'content-default'
  }
}
